import * as applicationStatus from '../../../pages/ApplicationStatus/components/locales/bi/translation.json'
import * as downloadScreen from '../../../pages/DownloadScreen/components/locales/bi/translation.json'
import * as applicationList from '../../../pages/ApplicationList/components/locales/bi/translation.json'
import * as updateEpccApplication from '../../../pages/UpdateEpccApplication/components/locales/en/translation.json'
import * as newEpccApplication from '../../../pages/NewEpccApplication/components/locales/bi/translation.json'
import * as newEpccInstructions from '../../../pages/NewEpccInstructions/components/locales/bi/translation.json'
import * as options from '../../../pages/Options/components/locales/bi/translation.json'
import * as profile from '../../../pages/Profile/components/locales/bi/translation.json'
import * as resetPassword from '../../../pages/ResetPassword/components/locales/bi/translation.json'
import * as signIn from '../../../pages/SignIn/components/locales/bi/translation.json'
import * as signUp from '../../../pages/SignUp/components/locales/bi/translation.json'
import * as emailVerification from "../../../pages/EmailVerification/components/locales/bi/translation.json"

export const text_bi={
    applicationStatus,
    downloadScreen,
    applicationList,
    newEpccApplication,
    newEpccInstructions,
    options,
    profile,
    resetPassword,
    signIn,
    signUp,
    updateEpccApplication,
    emailVerification
}
